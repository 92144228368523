import React from 'react';

function HelloWorldPage() {
  return (
    <div>
      <h1>Hello World</h1>
    </div>
  );
}

export default HelloWorldPage;
